<!-- This page can be used to inject a Vue Component into a Django Template

Usage:

1. In the Django Template add:
```html
... top of the file ...
{% load django_vite static %}
...
{% block content %}
... inside content block ...
{% vite_asset 'js/vue.js' %}
<div id="app" data-page="{{page|escape}}"></div>
{% endblock %}
```
Example: vpoc/templates/new-login.html

2. In the Django View add:
inertia_render(
    request,
    "InjectComponent",
    props={"component": "Name of the component file inside /components folder"},
    template_data={"inertia_layout": "current Django template file"}
)`
```py
def login(request):
...
    return inertia_render(
        request,
        "InjectComponent",
        props={"component": "CookieBanner"},
        template_data={"inertia_layout": "new-login.html"},
    )
```
Example: vpoc/accounts/views.py#L301
  -->
<template>
    <!-- eslint-disable-next-line vue/require-component-is -->
    <Component />
</template>

<script setup>
import { defineAsyncComponent } from "vue";

// eslint-disable-next-line no-unused-vars
const Component = defineAsyncComponent(() => import(`../components/${props.component}.vue`));

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    component: {
        type: String,
        required: true,
    },
});
</script>
